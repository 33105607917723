/* You can add global styles to this file, and also import other style files */

@import "./bootstrap/variables";
@import "./bootstrap/mixins";
@import "./bootstrap/bootstrap";
@import "./font-awesome/font-awesome";
@import "./fonts.scss";
@import "./images.scss";
@import "../assets/fonts/icons/style.css";

@-webkit-keyframes filterslide{
  from{ opacity: 0; visibility: hidden; left: 4rem;}
  to{ opacity: 1; visibility: visible; left: 0;}
}

@keyframes filterslide{
  from{ opacity: 0; visibility: hidden; left: 4rem;}
  to{ opacity: 1; visibility: visible; left: 0;}
}

/* ================== Generalize Styles =================== */
html, body {
  height: 100%;
  margin: 0;
}

a.alternate{ color: #44464b;}
.txt-critical{ color: #ed1b34;}
.txt-success{ color: #43a807;}
.txt-blue{ color: #0054a4 !important;}
.sharp-blue{ color: #056ed0 !important;}
.btn-back-arrow{
  font-size: 1.2rem; color: #44464b; margin-right: 7px;
}

.white-card-bordered{
  background: #fff;
  border: 1px solid #b7b9bd;
  border-radius: 0.35rem;
  position: relative;
}

.hidden{ display: none;}
.visible{ display: block;}

.card-white{ background: #fff; border-radius: 0.3125rem;}
.box-shadow{ box-shadow: 0 2px 7px 0px rgba(0, 0, 0, 0.1);}
.border-radius{ border-radius: 0.3125rem;}
a{ cursor: pointer;}
a.btn-outline-default{
  background: transparent;
  border: 1px solid #777;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.85rem;
  &:focus{
    outline: none;
    box-shadow: none;
  }
}

h1, h2, h3, h4, h5, h6{
  font-family: "Montserrat";
  color: #44464b;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.w900{ font-weight: 900 !important;}
.w800{ font-weight: 800 !important;}
.w700{ font-weight: 700 !important;}
.w600{ font-weight: 600 !important;}
.w500{ font-weight: 500 !important;}
.w400{ font-weight: 400 !important;}
.w300{ font-weight: 300 !important;}

.cursor-pointer{ cursor: pointer;}

.txt-black{ color: #000 !important;}

.main-title{
  font: 800 2.6rem "Montserrat";
  color: #44464b;
  font-weight: 800 !important;
}

.title-tagline{
  color: #44464b;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 1.12rem;
  text-align: center;
}

.btn-blue{
  background: #0054a4;
  border-radius: 0.3rem;
  font: normal 0.95rem "Montserrat";
  color: #fff !important;
  max-width: 320px;
  text-align: center;
  margin: 0 auto;
  text-decoration-line: none;

  &:disabled{
    background: #0054a4 !important
  }
  
  &:hover,
  &:active,
  &:focus,
  &:focus-visible{
    background: #0054a4 !important;
  }
}

.btn-blue:hover{
  color: #fff;
}

.btn-white, .btn-white:hover{
  background: #fff;
  border-radius: 4px;
  color: #44464b;
  line-height: 1.8;
  font-weight: 500;
}

.light-grey-bg{
  background-color: #f4f7fc;
}

.container1024{
  // max-width: 1024px;
}

.custom-font{
  font-family: "Montserrat";
}

/* =============== Header ============== */
.header-lg{
  .btn-blue{
    padding: 0.4rem 0.7rem;
    font-size: 0.85rem;
    border-radius: 0.25rem;
  }
  .primary-menu{
    a{
      position: relative;
      &.active-menu{
        &:after{
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          margin: 0 0 -35px 0;
          background: #ec1b35;
        }
      }
    }
  }
}

.footer-lg{
  background: #0b52aa;
  color: #fff;
  font-size: 0.85rem;
  *{
    color: #fff;
    font-size: 0.85rem;
  }
  p{
    color: #fff;
    i{
      &.fa{
        color: #fff;
      }
    }
  }
  .footer-signin{
    a{
      font-size: 0.8rem;
      line-height: 1;
      &:hover{
        text-decoration: underline;
        color: #fff;
      }
    }
    span{
      font-size: 0.7rem;
    }
  }
  .fa-phone{
     transform: rotate(90deg);
  }
  ul{
    padding: 0;
    margin: 0;
    list-style: none;
    li{
      padding: 0.5rem 0;
      a{
        display: block;
        padding: 2px 0;
        font-size: 0.77rem;
        color: #fff;
        text-decoration-line: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .social-brands{
    a{
      font-family: "Font Awesome 5 Brands";
      padding: 2px 20px 2px 0;
      font-size: 1.25rem;
      font-weight: 400;
      text-decoration-line: none;
      &:hover{
        color: #fff;
      }
    }
  }
  .copyright{
    background: #094a9c;
    p{
      color: #fff;
    }
  }
  .form-subscribe{
    input{
      border-radius: 0.2rem 0 0 0.2rem;
      background: #fff;
      border: 0;
      padding: 5px 10px;
      font-weight: 500;
      color: #333;
      height: 36px;
    }
    button{
      background: #e3002b;
      border-radius: 0 0.2rem 0.2rem 0;
      border: 0;
      color: #fff;
      font-size: 0.78rem;
      font-weight: 500;
      padding: 6px 10px 6px;
      height: 36px;
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
  }
}

/* --------- Mobile Footer --------- */
.mobile-footer{
  .app-logo{
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
  }
  .about-hth{
    p{
      color: #fff;
    }
    i{
      color: #fff;
    }
  }
  h6{
    font-size: 0.95rem;
  }
  .has-border-top{
    border-top: 1px solid #084798;
  }
  ul{
    li{
      text-align: center;
      a{
        font-size: 0.85rem;
      }
    }
  }
  .form-subscribe{
    input{
      border-radius: 0.3125rem;
      height: auto;
      line-height: 1;
      font-size: 0.95rem;
    }
    button{
      border-radius: 0.3125rem;
      height: auto;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
  .social-brands{
    a{
      padding: 2px 10px;
      margin: 10px 0 0;
      font-size: 1.5rem;
    }
  }
  .has-dd{
    position: relative;
    &:after{
      position: absolute;
      right: 0;
      top: 0;
      font-family: "Font Awesome 5 Free";
      background-color: #053577;
      content: "\f078";
      font-weight: 900;
      color: #fff;
      width: 27px;
      height: 27px;
      font-size: 0.8rem;
      text-align: center;
      margin: 18px 15px 0 0;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 0 0;
    }
    ul{
      display: none;
    }
    &.visible{
      &:after{
        content: "\f077";
        padding: 0;
      }
      ul{
        display: block;
      }
    }
  }
}

/* ========== Product Listing ============ */
.plan-box-wrapper{
  .plan-box{
    &:nth-child(odd){
      padding-right: 0.3rem !important;
    }
    &:nth-child(even){
      padding-left: 0.3rem !important;
    }
  }
}
.plan-tab{
  height: 100%;
  label{
    padding: 0.7rem 0.8rem 0.6rem 3rem;
    font-family: "Montserrat";
    line-height: 1.3;
    margin: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1.2px solid #eef2f7;
    position: relative;
    cursor: pointer;
    b{
      display: block;
      font-weight: 600;
      font-size: 1rem;
      color: #44464b;
    }
    span{
      font-size: 0.78rem;
      color: #666;
      font-weight: 500;
      margin-bottom: -1px;
      margin-top: 3px;
      display: block;
    }
  }
  input{
    display: none;
    &+label{
      &:before{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 4px;
        background: #eef2f7;
        position: absolute;
        content: "";
        left: 0;
        margin: 5px 0 0 0.8rem;
      }
    }
    &:checked{
      &+label{
        background: #0054a4;
        b, span{
          color: #fff;
        }
        &:after{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 0.9rem;
          width: 0.55rem;
          border-right: 4px solid #0054a4;
          border-bottom: 4px solid #0054a4;
          border-color: transparent  #0054a4 #0054a4 transparent;
          border-radius: 2px;
          transform: rotate(45deg);
          margin: 21px 0 0 1.3rem;
        }
      }
    }
  }
}

.product-item-card{
  background: #fff;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 7px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  font-family: "Montserrat";
  span{
    color: #76777b;
    font-weight: 500;
    font-size: 0.85rem;
  }
  h5{
    line-height: 1.2;
    margin: 0;
  }
  figure{
    background: #fff;
    margin: 10px 0;
    padding: 0 15px;
    text-align: center;
    .product-item-img{
      width: 100%;
      max-width: 140px;
      height: auto;
      margin: 0 auto;
    }
  }
  .price{
    span{
      font-size: 1.15rem;
      font-weight: 600;
      color: #0b4a91;
    }
  }
  .old-price{
    color: #44464b;
    text-decoration: line-through;
    font-size: 0.95rem;
    font-weight: 600;
    margin-right: 6px;
  }
  span{
    &.icon-info{
      color: #44464b;
      font-size: 1.18rem;
    }
  }
}

.rate-like{
  span{
    &.stars{
      color: #ffa62a;
      white-space: nowrap;
      font-size: 0.9rem;
    }
    &.like{
      color: #ec1c36;
      flex: 1 1 auto;
      font-size: 1.3rem;
    }
  }
  small{
    font-size: 0.9rem;
    font-weight: 600;
    color: #0b4a91;
  }
}

.filters{
  padding-left: 10px;
  .accordion{
    .card{
      border: 0 !important;
      border-bottom: 1px solid #76777b !important;
      button{
        width: 100%;
        padding: 1.6rem 0;
        text-align: left;
        display: flex;
        i{
          color: #44464b;
          &.fa-plus{
            display: none;
          }
          &.fa-minus{
            display: inline-block;
          }
        }
        &.collapsed{
          i{
            &.fa-minus{
              display: none;
            }
            &.fa-plus{
              display: inline-block;
            }
          }
        }
      }
      .card-header, .accordion-header{
        border: 0;
      }
      .card-body{
        padding: 0 0 1.2rem;
        margin: -5px 0 0;
        .filter-item{
          width: 100%;
          input{
            display: none;
            &+label{
              font-family: "Montserrat";
              font-size: 0.875rem;
              font-weight: 700;
              position: relative;
              display: block;
              margin: 0;
              padding: 0.6rem 2.2rem 0.6rem 0;
              cursor: pointer;
              &:before{
                position: absolute;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 4px;
                background: #e9e9e9;
                content: "";
                top: 0;
                right: 0;
                margin: 6px 0 0;
              }
            }
            &:checked{
              &+label{
                &:before{
                  background: #0b4a91;
                }
                &:after{
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 0.9rem;
                  width: 0.55rem;
                  border-right: 4px solid #fff;
                  border-bottom: 4px solid #fff;
                  border-color: transparent  #fff #fff transparent;
                  border-radius: 2px;
                  transform: rotate(45deg);
                  margin: 10px 0.5rem 0 0;
                }
              }
            }
          }
        }
      }
      &:not(:first-of-type){
        margin-top: auto;
      }
      h6{
        margin: 0;
        flex: 1 1 auto;
      }
    }
  }
  div[disabled]{
    input{
      &+label{
        &:before{
          background: #ddd !important;
        }
      }
    }
  }
}

.active-promotion{
  text-align: center;
  box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  padding: 0.75rem 0.8rem;
  p{
    color: #000;
    font-weight: 500;
    text-align: left;
    flex: 1 1 auto;
    margin: 0 15px 0 0;
    line-height: 1.2;
    font-size: 0.97rem;
  }
  a{
    &.btn{
      background: #0054a4;
      border-radius: 4px;
      color: #fff;
      flex: 1 0 auto;
      &:hover{
        color: #fff;
      }
      &:focus{
        box-shadow: none;
      }
    }
  }
}
.btn-multiline{
  background: #0054a4;
  border-radius: 4px;
  color: #fff !important;
  text-align: center;
  display: block;
  line-height: 1.3;
  margin: 1.15rem 0 0;
  &:focus{
    box-shadow: none;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible{
    background: #0054a4 !important;
  }
}

/*.filter-popup{
  background: #fff;
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 4rem;
  // animation: filterslide;
  // animation-duration: 0.35s;
  transition: 0.35s;
  visibility: hidden;
  opacity: 0;
  h3{
    margin: 1.5rem 0 0;
    padding: 0 0 1rem;
    border-bottom: 1px solid #ddd;
    font-size: 1.45rem;
    em{
      font-style: normal;
      font-weight: 600;
      font-size: 1.35rem;
      position: relative;
      top: -2px;
    }
    span{
      flex: 1 1 auto;
      margin: 0 30px 0 0;
    }
    a{
      color: #44464b;
      font-size: 1.4rem;
    }
  }
  &.show-popup{
    visibility: visible;
    opacity: 1;
    left: 0;
  }
  &.close-popup{
    left: 4rem;
    opacity: 0;
    visibility: hidden;
    transition: 0.35s;
  }
} */

.wellness-search{
  max-width: 300px;
  position: relative;
  input{
    padding: 10px 45px 10px 12px;
    border-radius: 0.3rem;
    border: 1px solid #aaa;
    font-size: 0.95rem;
    width: 100%;
  }
  .search-btn{
    background: none;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 8px 12px 7px 0;
    font-size: 1.2rem;
    i{
      color: #0054a4;
    }
  }
}
@media only screen and (max-width : 599px){
  .wellness-search{
    max-width: 100%;
  }
}

/* ============ Product Detail ============= */
.detail-accordion-card{
  .accordion{
    font-family: "Montserrat";
    .card, .accordion-item{
      button{
        &.collapsed{
          background: #fff;
        }
        &.btn{
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          width: 100%;
          padding-right: 1.45rem;
          padding-left: 1.45rem;
        }
        h5{
          margin: 0 20px 0 0 !important;
          flex: 1 1 auto;
          text-align: left;
        }
        i{
          color: #0054a4 !important;
        }
      }
    }
  }
  small, .small{
    font-size: 0.85rem;
    font-weight: 500;
  }
}

.detail-breadcrumb{
  color: #0054a4;
  a{
    color: #44464b;
    &:hover{
      text-decoration: underline;
    }
  }
}

/* =============== Shopping Cart ================ */
.shopping-cart-head{
  h4{
    font-size: 1.4rem;
    font-weight: 800;
  }
  span{
    font-size: 1rem;
  }
}

.cart-listing{
  background: #fff;
  border: 1px solid #b7b9bd;
  border-radius: 0.35rem;
  margin: 1rem 0 0;
  padding: 2.5rem 1rem 1rem;
  position: relative;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.2) inset;
  .cart-add-edit{
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 20px 0 0;
    .btn-del, .btn-edit{
      background: none;
      border: 0;
      color: #010101;
      font-weight: 500;
      font-size: 0.95rem;
      &:focus{
        outline: none;
      }
      &:hover{
        color: #ff0000;
      }
      // &+span{
      //   color: #aaa;
      //   font-weight: 400;
      // }
    }
    .btn-del{
      font-size: 0.95rem;
    }
    .btn-edit{
      &:hover{
        color: #0054a4;
      }
    }
  }
  .cart-list-img{
    width: 150px;
    flex: 0 0 auto;
    background: #fff;
    border-radius: 0.3125rem;
    img{
      max-width: 150px;
      min-width: 132px;
      max-height: none;
      height: auto;
      margin: 0 auto;
      width: 100%;
    }
  }
  .pet-name{
    width: 100%;
    img{
      background: #f4f7fc;
      border-radius: 150px;
      padding: 5px;
      width: 33px;
      height: auto;
    }
    h5{
      margin: 0;
      font-weight: 800;
      color: #0054a4;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      padding: 0 10px;
      line-height: 1.15
    }
  }
  .cart-product-head{
    span{
      color: #76777b;
      display: block;
      font-weight: 500;
    }
    h4{
      margin: 3px 0;
      line-height: 1.2;
    }
  }
  .cart-product-price{
    span{
      font-weight: 600;
      font-size: 0.9rem;
    }
    b{
      font-weight: 600;
      font-size: 1.2rem;
      color: #0054a4;
      margin: 0 0 0 10px;
      position: relative;
      top: -4px;
    }
  }
  .product-qty{
    .qty-field{
      max-width: 125px;
      button{
        background: #fff;
        border: 1px solid #0054a4;
        border-radius: 0.35rem 0 0 0.35rem;
        width: 38px;
        // max-width: 37px;
        // min-width: 37px;
        height: 35px;
        font-size: 1.8rem;
        line-height: 1;
        color: #0054a4;
        font-weight: 400;
        padding-bottom: 3px;
        flex: 0 0 auto;
        box-sizing: border-box;
        text-align: center;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        &:focus{
          outline: none;
        }
        strong{
          position: relative;
          top: -2px;
          font-weight: 400;
          // border-top: 1.2px solid #0054a4;
          // width: 10px;
          // height: 2px;
          // display: inline-block;
          // position: relative;
          // top: 1px;
          // box-shadow: 0 -0.5px 0 0 #0054a4;
        }
      }
      input{
        border-width: 1px 0 1px 0;
        border-color: #bfbfbf;
        border-style: solid;
        width: 42px;
        text-align: center;
        padding: 0 5px;
        border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        &+button{
          border-radius: 0 0.35rem 0.35rem 0;
          padding-left: 0.6rem;
          padding-right: 0.6rem;
          // padding-bottom: 0;
        }
        &:focus{
          outline: none;
        }
      }
    }
  }
  .promo-off{
    em{
      font-style: normal;
      color: #0054a4;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  .col-price{
    label{
      flex: 1 1 auto;
      margin: 0;
      font-weight: 500;
    }
    span{
      color: #0054a4;
      font-size: 1.45rem;
      font-weight: 600;
    }
  }
  &.shipping-detail-card{
    padding-bottom: 0;
    padding-top: 1rem !important;
    h3{
      font-size: 1.4rem;
      span{
        font-size: 1rem;
        font-weight: 500;
      }
    }
    button, a{
      &.btn{
        line-height: 1.15;
        font-weight: 500;
        flex: 0 0 auto;
        &:focus{
          outline: none;
          box-shadow: none;
          &:active{
            box-shadow: none;
            outline: none;
          }
        }
        &.btn-outline-primary{
          border-color: #0054a4;
          color: #0054a4;
          @media (min-width:1025px) {
            &:hover{   
              background: #0054a4;
              color: #fff;
            }
          }
          i{
            font-size: 1.05rem;
          }
        }
        &.btn-outline-dark{
          color: #44464b;
          border-color: #7f7f7f;
          &:hover{
            color: #fff;
            border-color: #0054a4;
            background: #0054a4;
          }
          @media (min-width:1025px) {
            &:hover, &:active{
              color: #fff;
              border-color: #0054a4;
              background: #0054a4;
            }
          }
          @media only screen and (max-width : 480px){
            &:hover, &:active{
              color: #fff;
              border-color: #0054a4;
              background: #0054a4;
            }
          }
        }
      }
    }
    .is-primary{
      &.border-top{
        border-top: 0 !important;
        padding-top: 0 !important;
      }
    }
    .set-primary{
      background: #0054a4;
      color: #fff;
      border-radius: 0.25rem;
      line-height: 1.1;
      padding: 0.75rem;
      border: 1px solid #0054a4;
      cursor: not-allowed;
    }
    label{
      font-size: 1rem;
      font-weight: 600;
      margin-right: 25px;
      i{
        &.fa{
          color: #0054a4;
          font-size: 1.45rem;
        }
      }
    }
    .custom-control{
      padding-left: 0;
      z-index: auto;
      input{
        +label{
          transition: none !important;
          padding-left: 1.5rem !important;
          &:before{
            border: 1.5px solid #fff !important;
            width: 12px;
            height: 12px;
            background: #fff;
            box-shadow: 0 0 0 1.5px rgba(0,0,0,0.7);
            transition: none !important;
            top: 0 !important;
            left: 0;
            margin-top: 7px !important;
          }
          &:after{
            display: none !important;
            background: none !important;
            transition: none !important;
          }
        }
        &:checked{
          +label{
            &:before{
              background: #0b4a91 !important;
            }
          }
        }
      }
    }
    .estimated-date{
      font-size: 1rem;
    }
    .delivery-cost{
      font-size: 1.05rem;
    }
    .app-invalid-feedback{
      max-width: 183px;
    }
  }
  .cart-info-readonly{
    line-height: 1.35;
    .form-group{
      border: 0;
      label{
        font-weight: 600;
        font-size: 0.8rem;
        padding: 0;
        margin: 0;
      }
      select{
        font-weight: 500;
        background-image: none;
        background: transparent;
        font-size: 0.8rem;
        padding: 0;
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 600px) {
    .cart-info-readonly{
      .d-flex {
        flex-direction: column;
      align-items: flex-start !important;
      .form-group {
        width: 100%;
        max-width: 250px;
        margin-left: 0 !important
      }
      }
    }
}
}

.popup-overlay{
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.5);
  // transform: translate(-50%, 0);
  // margin: 50px 0 0;
  .modal-dialog{

  }
}

/* -------- Confirm Order --------- */
.confirm-order{
  .product-price, .sub-total{
    color: #0054a4;
  }
  .product-price{
    font-size: 1.02rem;
  }
  .sub-total{
    font-size: 1.15rem;
  }
}

.app-order-summary{
  .card-promocode{
    // background: #fff;
    // border-radius: 0.35rem;
    // border: 1px solid #b7b9bd;
    .promo-field{
      input{
        border: 1px solid #bfbfbf;
        border-right: 0;
        padding: 5px 15px;
        line-height: 1;
        font-weight: 500;
        color: #44464b;
        border-radius: 0.25rem 0 0 0.25rem;
        height: 2.75rem;
      }
      button{
        background: #0054a4;
        border: 0;
        border-radius: 0 0.25rem 0.25rem 0;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1;
        width: 110px;
        padding: 0.25rem 0.25rem;
        height: 44px;
        cursor: pointer;
      }
    }
    .applied-promo{
      label{
        color: #43a807;
        font-weight: 500;
        font-size: 0.85rem;
      }
      button{
        &.btn{
          &.btn-link {
            text-decoration: underline;
            padding: 0 0 0 10px;
            font-weight: 500;
            flex: 0 0 auto;
          }
        }
      }
    }
    .promo-error{
      font-size: 0.8rem;
    }
  }
  .order-summary-head{
    font-size: 1.4rem;
    margin: 0;
  }
  .summary-card{
    background: #fff;
    border-radius: 0.35rem;
    border: 1px solid #b7b9bd;
    .order-total{
      font-size: 1.1rem;
      color: #0054a4;
    }
    .app-after-summary{
      border-top: 1px solid #bfbfbf;
      font-size: 0.85rem;
      font-weight: 500;
    }
    button{
      background: #0054a4;
      border-radius: 0.25rem;
      width: 100%;
      color: #fff;
      font-weight: 400;
      font-size: 0.9rem;
      text-align: center;
      cursor: pointer;
      border: 0;
      padding: 0.9rem;
      &:focus{
        outline: none;
      }
    }
    .promo-summary{
      .promo-card{
        background: #f4f7fc;
        border: 1px solid #e2e7ef;
        color: #ed1b34;
        padding: 3px 7px;
        border-radius: 0.15rem;
        font-size: 0.85rem;
        position: relative;
        top: -2px;
        a{
          color: #44464b;
          cursor: pointer;
        }
      }
      .promo-price{
        // flex: 1 1 auto;
      }
    }
  }
}


// ========= Common styles
.app-status {
  text-transform: capitalize;
}

.app-title {
  color: $app-blue-100;

  &.alternate {
    background-color: $app-blue-100;
    color: $white;
  }
}

.app-card-header {

  &.selected {
    background-color: lighten($app-blue-100, 35%);
  }

  /* ng V16
   i {
     font-size: 18px;     
  } */
}

.app-scrollable {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.app-icon {
  color: $app-blue-100;
}

.app-pet-img {
  min-width: 110px;
  max-width: 110px;
  max-height: 110px;
  min-height: 110px;
}

.app-pet-img-sml {
  min-width: 55px;
  max-width: 55px;
  max-height: 55px;
  min-height: 55px;
}

/* ========= Pets / Shop  ========== */
.msg-bar{
  background: #0054a4;
  padding: 10px 0;
  color: #fff;
  *{
    color: #fff;
  }
  i{
    font-size: 1.3rem;
    margin: 0 12px 0 0;
  }
  p{
    color: #cad9e8;
    padding: 0; margin: 0;
    flex: 1 1 auto;
    font-family: "Montserrat";
    font-weight: 500;
    b{
      color: #fff;
      font-weight: 600;
    }
  }
  a{
    &.msg-btn{
      // border: 1px solid #fff;
      background: #fff;
      border-radius: 3px;
      display: inline-block;
      font-size: 0.8rem;
      font-family: Montserrat;
      padding: 8px 15px;
      margin: 0 0 0 20px;
      white-space: nowrap;
      color: #44464b;
      font-weight: 500;
      text-decoration-line: none;
      &:hover{
        color: #44464b;
      }
    }
  }
}

/* ============== Pet Profile ============= */
.pet-profile{
  .pet-profile-title{
    h5{
      font-size: 1.3rem;
      padding-right: 15px;
      width: 70%;
      overflow: HIDDEN;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span{
      flex: 0 0 auto;
      a{
        &.btn-outline-default{
          flex: 0 0 auto;
        }
      }
    }
    .main-title{
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 100px;
    }
  }
  h5{
    font-size: 1.2rem;
  }
  .profile-img{
    figure{
      width: 100px;
      height: 100px;
      background: #fff;
      border-radius: 250px;
      padding: 20px;
      margin: 0 auto;
      img{
        min-width: auto;
        max-width: none;
        min-height: auto;
        max-height: none;
        color: #0054a4;
      }
    }
  }
  .active-inactive-card{
    background: #fff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08);
    height: 100%;
    h5{
      font-size: 1.02rem;
      margin: 0 0 5px;
    }
    span{
      color: #0054a4;
      font-size: 0.82rem;
    }
  }
  .card-foot{
    p{
      margin: 0;
      label{
        margin: 0;
        color: #78777c;
        font-weight: 500;
        font-size: 0.85rem;
        display: block;
      }
      b{
        font-weight: 700;
        font-size: 0.92rem;
        em, i{
          width: 8px;
          height: 8px;
          border-radius: 20px;
          background: #84cd07;
        }
        i{
          background: #dadada;
        }
      }
    }
  }
  .link-more-plan{
    text-decoration: underline !important;
    font-size: 1rem;
  }
}

/* profile Add/Edit */
.profile-edit{
  .profile-edit-title{
    h5{
      font-size: 1.2rem;
    }
  }
  figure{
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 250px;
    padding: 20px;
    margin: 0 auto;
    img{
      min-width: auto;
      max-width: none;
      min-height: auto;
      max-height: none;
      color: #0054a4;
    }
  }
  .name-field-container{
    max-width: 400px;
    margin: 0 auto;
  }
  .form-group{
    &.is-invalid{
      border: 0;
      input{
        border: 1px solid #dc3545;
        border-radius: 0.25rem;
      }
    }
  }
  input[type=text]{
    font-weight: 500;
    font-size: 1.1rem;
    padding: 10px 15px;
  }
  .select-pet{
    input{
      display: none;
      &+label{
        cursor: pointer;
        &:before, &:after{
          display: none;
        }
        span{
          font-size: 1rem;
        }
      }
      &:checked{
        &+label{
          figure{
            box-shadow: 0 0 0 4px #0054a4 inset;
            img{
              color: #fff;
            }
          }
        }
      }
    }
  }
  button{
    &.btn-blue{
      max-width: 100%;
    }
  }
}

.detail-price-card{
  .form-group{
    select{
      background-image: url("../assets/images/down-arrow.png");
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 94% center;
    }
  }
}



/* ============ Web Popups =========== */
.modal-dialog{
  .modal-content{
    background: #fff;
    border-radius: 0.3125rem;
    .modal-header{
      border-bottom: 1px solid #ddd;
      .modal-title{
        margin: 0;
        padding: 0;
        font-size: 1.25rem;
        color: #44464b;
        text-align: left;
        font-family: "Montserrat";
        line-height: 1.2;
      }
      .close{
        color: #44464b;
        position: relative;
        top: 1px;
        background-color: inherit;
        border: none;
      }
    }
    .modal-body{
      font-family: "Montserrat";
      color: #44464b;
      .app-mobile-layout{
        background: transparent;
      }
      label{
        display: block;
        text-align: left;
      }
      p{
        font-size: 0.9rem;
      }
      .form-group{
        border-color: #bfbfbf;
        border-width: 1px;
        &.is-invalid{
          border-width: 1px;
          border-color: #dc3545;
        }
        input, select{
          font-weight: 500;
          padding: 0.5rem 0.75rem;
          font-size: 0.95rem;
          border-width: 1px;
        }
      }
      .h1, .h2, .h3, .h4, .h5, .h6{
        font-family: "Montserrat";
        color: #44464b;
        line-height: 1.4;
      }
      .app-cart-img{
        width: auto !important;
      }
      input[type=radio]{
        &+label{
          &:before{
            border: 1.5px solid #fff !important;
            width: 12px;
            height: 12px;
            background: #fff;
            box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.7);
            -webkit-transition: none !important;
            transition: none !important;
            top: 0 !important;
            left: 0;
            margin-top: 4px !important;
          }
          &:after{
            display: none;
          }
        }
        &:checked{
          &+label{
            &:before{
              background: #0b4a91;
            }
          }
        }
      }
      .close{
        padding: 0.5rem;
        top: 0;
        right: 0;
        margin: 4px 10px 0 0;
        position: absolute;
        color: #44464b;
        background-color: inherit;
        border: none;
        font-size: large;
      }
    }
    .modal-footer{
      font-family: "Montserrat";
      border-top: 1px solid #ddd;
      justify-content: center;
      .btn{
        font-weight: 500;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 0.3rem;
        text-transform: uppercase;
        &.btn-secondary{
          background: #fff;
          color: #0054a4;
          border: 1px solid #0054a4;
        }
        &.btn-primary{
          background: #0054a4;
          color: #fff;
        }
        &:focus{
          box-shadow: none !important;
        }
        &.btn-blue{
          margin: 0;
        }
      }
    }




    // h3{
    //   margin: 1.5rem 0 0;
    //   padding: 0 0 1rem;
    //   border-bottom: 1px solid #ddd;
    //   font-size: 1.4rem;
    //   em{
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 1.35rem;
    //     position: relative;
    //     top: -1px;
    //   }
    //   span{
    //     flex: 1 1 auto;
    //     margin: 0 30px 0 0;
    //   }
    //   a{
    //     color: #44464b;
    //     font-size: 1.4rem;
    //   }
    // }
    // .form-group{
    //   border-color: #bfbfbf;
    //   border-width: 1px;
    //   &.is-invalid{
    //     border-width: 1px;
    //     border-color: #dc3545;
    //   }
    //   ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //     color: #76777b;
    //   }
    //   ::-moz-placeholder { /* Firefox 19+ */
    //     color: #76777b;
    //   }
    //   :-ms-input-placeholder { /* IE 10+ */
    //     color: #76777b;
    //   }
    //   :-moz-placeholder { /* Firefox 18- */
    //     color: #76777b;
    //   }
    //   input:not([type=submit]), input:not([type=button]), input:not([type=radio]), input:not([type=checkbox]), select{
    //     line-height: 2;
    //     font-size: 1.15rem;
    //   }
    // }
    // p{
    //   margin: 0;
    //   input{
    //     display: none;
    //     &+label{
    //       position: relative;
    //       display: block;
    //       padding: 1.1rem 0 1.1rem 3rem;
    //       font-size: 1.1rem;
    //       margin: 0;
    //       border-bottom: 1px solid #ddd;
    //         &:before{
    //         width: 0.5rem;
    //         height: 0.5rem;
    //         content: "";
    //         position: absolute;
    //         border-radius: 100px;
    //         left: 0;
    //         top: 0;
    //         background: transparent;
    //         border: 0.4rem solid #fff;
    //         box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    //         box-sizing: content-box;
    //         margin: 1.2rem 0 0 0.9rem;
    //       }
    //     }
    //     &:checked{
    //       &+label{
    //         &:before{
    //           background: #0054a4;
    //         }
    //       }
    //     }
    //     &[type="radio"]{
    //       &:checked{
    //         &+label{
    //           color: #0054a4;
    //         }
    //       }
    //     }
    //   }
    // }
    // .btn{
    //   line-height: 1.6;
    // }
  }
} /* ------ End Web Popup------ */


/* ============ Mobile Menu =========== */
.app-menu-window{
  .modal-dialog{
    width: 100%;
    padding-left: 20%;
    margin: 0;
    position: fixed;
    right: 0;
    .modal-content{
      background: url(../assets/images/menu-bg.jpg) no-repeat right 0 #0054a4;
      background-size: 123%;
      border-radius: 0;
      overflow: auto;
      .close-tab{
        content: "\f00d";
        position: fixed;
        // left: 0;
        // top: 0;
        font-family: "Font Awesome 5 Free";
        color: #fff;
        width: 42px;
        height: 42px;
        border: 0;
        padding: 0;
        border-radius: 0.3125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0b4b92;
        margin: 54px 0 0 -57px;
        font-weight: 900;
        font-size: 1.15rem;
      }
      // .mobile-menu-tabs{
      //   overflow: auto;
      // }
      .app-mobile-layout{
        background: transparent;
        .app-menu{
          background: transparent;
          .app-logo{
            margin: 25px 35px 15px 15px;
            width: 90px;
            height: 90px;
            background-size: 90px 90px;
          }
          a{
            border: 0 !important;
            color: #fff;
            font-size: 1.15rem;
            font-weight: 400;
            text-align: right;
            padding: 16px 50px 16px 30px;
            text-decoration-line: none;  
            i{
              display: none;
            }
          }
          .menu-social-media{
            padding: 0 40px 0 0;
            a{
              font-family: "Font Awesome 5 Brands";
              font-weight: 400;
              font-size: 1.4rem;
              color: #fff;
              padding: 10px 7px !important;
            }
          }
        }
      }
    }
  }
}

/* =============== Home Page - Login =============== */
.home-body{
  color: #76777b;
  font-size: 1rem;
  a{
    color: #003da5;
    &:hover{
      text-decoration: underline;
    }
  }
  h2{
    color: #44464b;
    font-size: 2.25rem;
    line-height: 1.15;
    max-width: 980px;
    font-weight: 800;
  }
  .home-callout{
    img{
      max-width: 100%;
    }
    p{
      line-height: 1.7;
    }
  }
  .home-note{
    font-size: 0.87rem;
  }

  .pet-section{
    background: url("../assets/images/pet-section-bg.jpg") no-repeat 0 0;
    background-size: cover;
    color: #fff;
    h2{
      color: #fff;
    }
    *{
      color: #fff;
    }
    p{
      font-size: 1.1rem;
      line-height: 1.4;
    }
    .btn-blue{
      display: inline-block;
      text-transform: uppercase;
      font-weight: 600 !important;
      font-size: 1.02rem;
      background: #003da5;
      &:hover{
        text-decoration: none;
        background: #0647b4;
      }
    }
  }
}

.vet-section{
  background: url("../assets/images/vet-section-bg.jpg") no-repeat center top;
  background-size: cover;
  p{
    max-width: 380px;
    line-height: 1.8;
    a{
      color: #76777b;
      &:hover{
        text-decoration: underline;
        color: #003da5;
      }
    }
  }
}

.how-works{
  text-align: center;
  position: relative;
  &:before{
    width: 60%;
    height: 1px;
    border-top: 2px solid #44464b;
    position: absolute;
    top: 0;
    left: 50%;
    content: "";
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    margin: 54px 0 0;
  }
  h4{
    font-size: 1.3rem;
    line-height: 1.35;
    margin: 0;
  }
  .step-icon{
    width: 110px;
    height: 110px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 30px 27px;
    border-radius: 500px;
    box-shadow: 0 0 0 15px #fff;
    &.is-red{
      background: #e4002b;
    }
    &.is-blue{
      background: #003da5;
      padding: 30px 24px;
    }
    img{
      max-width: 100%;
      margin: 0;
    }
  }
}


/* ================= App Mobile View =============== */
.app-mobile-layout {
 // background: #f4f7fc;
  .app-logo-icon{
    width: 60px;
    height: 60px;
    background-size: 60px 60px;
  }
  .main-title-mob{
    font-size: 1.4rem;
    line-height: 1.25;
    padding-top: 1rem;
    a{
      &.pet-back{
        color: #44464b;
        padding: 5px 8px 5px 0;
        position: relative;
        top: 2px;
      }
    }
  }
  .mob-card-wrapper{
    padding: 0 1.45rem;
    .mob-card-container{
      padding: 0.65rem;
    }
  }
  .app-cart-img {
    min-width: 100px;
    max-width: 100px;
    max-height: 200px;
  }
  .app-animated-page {
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
  }
  .title-wrapper{
    .main-title{
      font: 800 1.32rem "Montserrat";
      color: #44464b;
      flex: 1 1 auto;
    }
    .bordered-btn-white{
      background: #fff;
      border-radius: 4px;
      padding: 6px 15px;
      font-family: "Montserrat";
      font-size: 0.85rem;
      color: #44464b;
      display: inline-block;
      border: 1px solid #babbbd;
      font-weight: 500;
    }
  }
  .msg-bar{
    border-radius: 3px;
    p{
      font-size: 0.8rem;
      line-height: 1.25;
    }
    a{
      &.msg-btn{
        border-radius: 4px;
        padding: 6px 8px;
        font-size: 0.85rem;
      }
    }
  }
  .btn-blue{ max-width: none;}

  /* listing */
  .title-tagline{
    text-align: left;
    font-size: 1rem;
    line-height: 1.3;
  }
  .filters{
    padding-left: 0;
    .accordion{
      .card, .accordion-item{
        //border-bottom: 1px solid #ddd !important;  ng V16
        padding: 0 0.80rem;
        button{
          padding-left: 0.80rem;
          padding-right: 0.80rem;
          &.collapsed{
            background: #f5f5f5;
          }
          h6{
            font-weight: 500;
            font-size: 1.1rem;
          }
          i{
            font-size: 1.2rem;
            color: #0054a4;
          }
        }
        .card-body{
          background: #f5f5f5;
          padding: 0;
          .filter-item{
            padding: 0.6rem 0.8rem;
            border-top: 1px solid #ddd;
            input{
              &+label{
                font-size: 0.88rem;
                font-weight: 500;
                &:before{
                  margin-top: 11px;
                }
                &:after{
                  margin-top: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .product-item-card{
    .price{
      span{
        font-weight: 700;
      }
    }
    .old-price{
      color: #76777b;
      i{
        color: #44464b;
        font-size: 1.2rem;
        line-height: 1;
        margin-left: 0.55rem;
      }
    }
  }

  .active-promotion{
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    text-align: center;
    padding: 0;
    p{
      margin: 0 0 0.6rem 0;
    }
    a{
      &.btn{
        flex: none;
        width: 100%;
        line-height: 2;
        &:focus{
          color: #fff;
        }
      }
    }
  }
  .detail-accordion-card{
    .accordion{
      .card, .accordion-item{
        border-bottom: 1px solid #ddd !important;
        &:first-child{
          border-top: 1px solid #ddd !important;
        }
        button{
          &.btn{
            padding-right: 1.15rem;
            padding-left: 1.15rem;
          }
        }
      }
    }
    .small{
      font-size: 0.9rem;
    }
  }

  /* --- Cart Mobile ----*/
  .cart-listing{
    position: relative;
    padding-top: 2.6rem;
    &.shipping-detail{
      padding-top: 1rem;
    }
    .deleteItem{
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
      a{
        font-size: 0.95rem;
        color: #010101;
        &.fa-pencil-alt{
          font-size: 0.95rem;
        }
      }
    }
    .pet-name{
      flex-wrap: wrap;
      width: 25vw !important;
      img{
        padding: 0 5px;
        margin-bottom: 3px;
      }
      h5{
        font-size: 0.95rem;
        margin: 0;
        text-align: center;
        color: #0054a4;
      }
    }
    .cart-product-head{
      h4{
        font-size: 1rem;
      }
    }
    .cart-list-img{
      width: 90px;
      img{
        max-width: 100%;
        min-width: 100%;
      }
    }
    .cart-product-price{
      b{
        font-size: 1.2rem;
        position: relative;
        top: -4px;
      }
    }
    .col-price{
      span{
        font-weight: 600;
      }
    }
    .product-qty{
      .qty-field{
        input{
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
    &.shipping-detail-card{
      padding-top: 0.7rem !important;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      h3{
        font-size: 1.2rem;
      }
      .custom-control{
        input{
          & + label{
            &:before{
              top: 0 !important;
              margin: 0.4rem 0 0 !important;
            }
          }
        }
      }
      .app-invalid-feedback{
        max-width: 183px;
      }
    }
  }

  .mobile-promo-off{
    font-size: 1.05rem;
  }
  .mobile-subtotal{
    font-size: 1.1rem;
    b{
      font-weight: 700;
      font-size: 1.8rem;
      color: #0054a4;
    }
  }

  .mobile-checkout{
    a, button{
      background: #0054a4;
      border-radius: 0.25rem;
      width: 100%;
      color: #fff;
      font-weight: 400;
      font-size: 0.9rem;
      text-align: center;
      cursor: pointer;
      border: 0;
      padding: 0.9rem;
    }
  }

  .app-order-summary{
    font-size: 1.1rem;
    .order-summary-head{
      font-size: 1.2rem;
    }
    .summary-card{
      .app-after-summary{
        font-size: 1rem;
      }
      .promo-summary{
        .promo-card{
          font-size: 0.95rem;
        }
      }
    }
    .promo-error{
      font-size: 0.85rem;
    }
  }

  .detail-sku{
    color: #666;
    font-weight: 600;
    font-size: 0.85rem;
  }

  /* ------ Mobile Popup Slide ------ */
  .mobile-popup{
    background: #fff;
    position: fixed;
    z-index: 1100;
    width: 100%;
    height: 100%;
    left: 4rem;
    top: 0;
    transition: 0.35s;
    visibility: hidden;
    opacity: 0;
    form{
      max-height: calc(100vh - 104px);
      overflow: auto;
      padding-bottom: 1.5rem;
    }
    h3{
      margin: 1.5rem 0 0;
      padding: 0 0 1rem;
      border-bottom: 1px solid #ddd;
      font-size: 1.4rem;
      em{
        font-style: normal;
        font-weight: 600;
        font-size: 1.35rem;
        position: relative;
        top: -1px;
      }
      span{
        flex: 1 1 auto;
        margin: 0 30px 0 0;
      }
      a{
        color: #44464b;
        font-size: 1.4rem;
      }
    }
    .form-group{
      border-color: #bfbfbf;
      border-width: 1px;
      &.is-invalid{
        border-width: 1px;
        border-color: #dc3545;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #76777b;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #76777b;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #76777b;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #76777b;
      }
      input:not([type=submit]), input:not([type=button]), input:not([type=radio]), input:not([type=checkbox]), select{
        line-height: 2;
        font-size: 1.15rem;
      }
    }
    p{
      margin: 0;
      input{
        display: none;
        &+label{
          position: relative;
          display: block;
          padding: 1.1rem 0 1.1rem 3rem;
          font-size: 1.1rem;
          margin: 0;
          border-bottom: 1px solid #ddd;
            &:before{
            width: 0.5rem;
            height: 0.5rem;
            content: "";
            position: absolute;
            border-radius: 100px;
            left: 0;
            top: 0;
            background: transparent;
            border: 0.4rem solid #fff;
            box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
            box-sizing: content-box;
            margin: 1.2rem 0 0 0.9rem;
          }
        }
        &:checked{
          &+label{
            &:before{
              background: #0054a4;
            }
          }
        }
        &[type="radio"]{
          &:checked{
            &+label{
              color: #0054a4;
            }
          }
        }
      }
    }
    .btn{
      line-height: 1.6;
      &.btn-secondary{
        background: #fff;
        color: #0054a4;
        border: 1px solid #0054a4;
        &:focus{
          background: #fff;
        }
      }
    }
    &.show-popup{
      visibility: visible;
      opacity: 1;
      left: 0;
    }
  } /* ------ End Mobile Popup Slide ------ */

  .app-table-container{
    table{
      width: auto;
      margin: 0 -1.2rem;
    }
  }
} /* end app-mobile-layout*/


.form-payment{
  .cards-label{
    font-size: 0.9rem;
    line-height: 1.2;
  }
  .c-cards{
    border-top: 1px solid #ddd;
    img{
      max-width: 170px;
    }
  }
  .StripeElement{
    border-radius: 0.25rem;
    border: 1px solid #bbb;
    padding: 0.95rem 1rem;
    margin: 16px 0;
    height: 52px;
    input{
      font-size: 1rem !important;
    }
  }
}

/* ================= App Full View =============== */
.app-full-layout {
  .app-cart-img {
    min-width: 200px;
    max-width: 200px;
    max-height: 400px;
  }
}

.app-table-container {
  // overflow: auto;
  table {
    width: 100%;
    // min-width: 375px;
    // border-bottom: $table-border-width solid $table-border-color;
    background: #fff;
    // tbody tr:nth-of-type(#{$table-striped-order}) {
    //   background-color: #f4f7fc;;
    // }

    // tbody tr:first-child {
    //   // background-color: transparent;
    //   // border-bottom: $table-border-width solid $table-border-color;
    //   // font-weight: bold;
    // }

    // tbody tr:not(:first-child) {
    //   border-right: $table-border-width solid $table-border-color;
    //   border-left: $table-border-width solid $table-border-color;
    //   vertical-align: top;
    tr{
      &.TableHeader{
        td{
          background: #0054a4;
          color: #fff;
          font-size: 0.95rem;
          padding: 18px 15px;
        }
      }
    }

      td {
        padding: 15px 10px;
        background: #f4f7fc;
        line-height: 1.3;
        border: 2px solid #fff;
      }
    // }
  }
  ul{
    padding-left: 0 !important;
    margin-bottom: 0 !important;
    list-style-type: none;
    li{
      position: relative;
      padding-left: 1.45rem;
      &:before{
        position: absolute;
        content: "\f054";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 0.8rem;
        color: #0054a4;
        top: 0;
        left: 0;
      }
    }
  }
}

@media (min-width : 992px) and (max-width : 1199px){
  .active-promotion{
    p{
      margin-right: 10px;
      font-size: 0.85rem;
    }
  }
}

@media (min-width : 769px) and (max-width : 1024px){
  .pet-food{
    margin-top: -7rem !important;
  }
}

@media (min-width : 576px) and (max-width : 768px){
  .pet-food{
    margin-top: -8rem !important;
  }
}

@media (max-width : 992px){
  .vet-section{
    position: relative;
    &:before{
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      content: "";
      top: 0;
      left: 0;
    }
    *{
      color: #000;
      text-shadow: 0 0 20px rgba(255,255,255,0.8);
    }
    h3{
      color: #333;
    }
  }
  .home-body{
    .pet-section{
      position: relative;
      h2{
        color: #333;
        text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
      }
      p{
        color: #222;
        line-height: 1.6;
        text-shadow: 0 0 20px rgba(255,255,255,0.8), 0 0 20px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.8);
      }
      &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.7);
        content: "";
        top: 0;
        left: 0;
      }
    }
  }
}

@media only screen
and (max-width : 425px){
  .cart-listing{
    .product-qty{ display: block !important;}
    .col-price{ display: block !important; text-align: left; margin-top: 1rem;}
  }
}

@media only screen
and (max-width : 575px){
  .modal-dialog{
    margin: 15px;
    .modal-content{
      p{
        font-size: 0.95rem;
      }
    }
    .modal-footer{
      flex-direction: column;
      .btn-secondary, .btn-primary{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        width: 100%;
        margin: 0 !important;
        font-size: 1rem;
      }
      button{
        &+button{
          &.btn{
          margin-top: 12px !important;
          }
        }
      }
    }
  }
}

/* ================= Edit delivery date =============== */
.delivery-list{
  .modal-dialog{
    max-width: 580px !important;
    .modal-footer{
      .btn-secondary{
        font-size: 0.85rem;
      }
      @media only screen and (max-width : 575px){
        .btn-secondary{
          font-size: 0.94rem;
        }
      }
    }
  }
}
.list-wrapper{
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}
.edit-delivery-card{
  border: 0;
  box-shadow: none;
  h5{
    color: #0054a4;
    font-size: 0.86rem;
    text-transform: uppercase;
  }
  h6{
    color: #0054a4;
  }
  h4{
    font-size: 1.1rem;
    line-height: 1.2;
  }
  input{ display: none;
    &:checked{
      &+ label{
        border: 1px solid #46a3ee;
        box-shadow: 0 0 0 1.2px #46a3ee inset;
      }
    }
  }
  label{
    border: 1px solid #b7b9bd;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2) inset;
    padding: 1rem;
    margin: 1rem 0 0;
    position: relative;
    background: #fff;
    border-radius: 0.35rem;
    .list-container{
      border-bottom: 1.2px solid #eee;
      padding-bottom: 15px;
      &:last-child{
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  img{
    max-height: 100px;
    max-width: 100%;
  }
  @media only screen and (max-width : 575px){
    img{
      max-width: 120%;
    }
  }
  &:first-child{
    label{
      margin-top: 0;
    }
  }
}

.estimated-grid{
  padding: 1rem;
  border: 1px solid #d6d6d6;
  border-radius: 0.3125rem;
  span{
    color: #0054a4;
    font-size: 0.85rem;
    text-transform: uppercase;
    display: block;
  }
  h5{
    line-height: 1.2;
  }

}

.delivery-radio-list{
  margin-bottom: 15px;
  label{
    padding-left: 22px !important;
    font-weight: 700;
  }
  .date-picker{
    padding: 0 0 0 39px;
    input{
      border: 1px solid #bbb;
    }
  }
}

/* ------------ Main Menu Special Case ------------- */
.hamburger-tablet-view{ display: none;}
@media (min-width : 576px) and (max-width : 1023px){
  .primary-menu{
    visibility: hidden;
  }
  .btn-logout{
    display: none;
  }
  .hamburger-tablet-view{
    display: block;
  }
}

.loader-container {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
// Tooltip

.tooltip{
  @media only screen and (max-width : 480px){
    left: -2rem !important;
    right: 16rem !important;
    z-index: 1;
  .tooltip-inner{
    max-width: 190px !important;
  }  
}
}

//ng V16
.accordion-header > button {
  width: 100%;
  border: none !important;
  //background: none !important;
  background: #fff;
  padding: 10px;
  .panel-title-container{
    border-bottom: 1px solid #76777b !important;
  }
  .panel-open{
    border-bottom: none;
  }

}

.carousel .carousel-indicators{
  display: none;
}

.carousel .carousel-inner{
  .visually-hidden{
    display: none;
  }
}

// .carousel .carousel-indicators{
//   display: none;
// }

// .carousel .carousel-inner{
//   .visually-hidden{
//     display: none;
//   }
// }

.accordion-button::after{
  width: 0px !important;
}

.fa-arrow-left{
  text-decoration: none;
}

a {
  text-decoration: none;
}
.edit-form{
  .form-group {
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid #dadada;
    box-sizing: content-box;
    padding: 1px;
    min-height: 35px;
    margin-bottom: 1rem;
  }
  input, select{
    border: none !important;
  }
}
button.accordion-button,
button.accordion-button:focus,
button.accordion-button:active,
button.accordion-button:hover {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}
.ordersAcc, .subsAcc, .supportAcc{
  .accordion-item:not(:first-of-type) {
    margin-top: 20px;
  }
  .accordion-item {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 0;
  }
}

.list-unstyled {
  padding-left: 0.5rem !important;
}
@media (min-width: 1200px) {
  .text-xl-left {
      text-align: left !important;
  }
}

.form-group.is-invalid {
  border-color: #dc3545 !important;
  border-width: 2px !important;
  padding: 0 !important;
}
.frequencyDropdown .modal-dialog .modal-content .modal-body .form-group{
  border: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  
}
.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1;
  color: #000;
  text-shadow: none;
  font-size: 1.3125rem;
}

.whats-new-modal{
  padding:0px;
  .close{
    color:#fff !important;
    z-index: 1;
  }
}